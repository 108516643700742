import $http from '@/utils/http';

export function getUserList() {
  return $http.get('/v2/storage/userspace/users');
}

export function getDirectoryList(param) {
  return $http.get('/v2/storage/userspace/path/list', param);
}

export function deleteFile(param) {
  return $http.post('/v2/storage//object/delete', param);
}
