var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"main-title"},[_c('div',[_vm._v("用户空间管理")]),_c('div',{staticClass:"button-bar"},[_c('el-tooltip',{attrs:{"content":"刷新"}},[_c('el-button',{attrs:{"size":"mini","circle":"","icon":" el-icon-refresh"},on:{"click":_vm.refresh}})],1),(_vm.directoryPrefix)?_c('el-tooltip',{attrs:{"content":"上一级"}},[_c('el-button',{attrs:{"size":"mini","circle":"","icon":"el-icon-back"},on:{"click":_vm.goBack}})],1):_vm._e(),(!_vm.isUserList)?_c('el-tooltip',{attrs:{"content":"回到用户目录"}},[_c('el-button',{attrs:{"size":"mini","circle":"","icon":"el-icon-user"},on:{"click":_vm.goTop}})],1):_vm._e(),_c('el-input',{staticStyle:{"padding-left":"10px"},attrs:{"placeholder":"请输入过滤内容","clearable":"","size":"mini"},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1)]),_c('div',{staticClass:"dir-container"},[(_vm.isUserList)?[(_vm.users.length == 0)?[_c('div',{staticClass:"empty-data"},[_c('el-empty',{attrs:{"image-size":100,"description":"暂无数据"}})],1)]:_vm._l((_vm.users),function(user,index){return _c('div',{key:index,staticClass:"dir-item",attrs:{"title":'使用空间: ' +
            parseFloat(user.size / 1024 / 1024).toFixed(2) +
            ' M\n总空间: ' +
            parseFloat(user.user_space_size / 1024 / 1024).toFixed(2) +
            ' M'},on:{"click":function($event){return _vm.enterUser(user)}}},[_c('img',{staticClass:"dir-pic",attrs:{"alt":"User Icon","src":_vm.pics.userPic}}),_c('el-progress',{attrs:{"stroke-width":8,"percentage":user.percentage}}),_c('div',{staticClass:"dir-title"},[_vm._v(_vm._s(user.title))])],1)})]:[(_vm.directories.length === 0 && _vm.filterFiles.length === 0)?[_c('div',{staticClass:"empty-data"},[_c('el-empty',{attrs:{"image-size":100,"description":"暂无数据"}})],1)]:[_vm._l((_vm.directories),function(directorie,index){return _c('div',{key:'dir-' + index,staticClass:"dir-item",on:{"click":function($event){return _vm.enterDirectory(directorie.Prefix)}}},[_c('img',{staticClass:"dir-pic",attrs:{"alt":"Dir icon","src":_vm.pics.dirPic}}),_c('div',{staticClass:"dir-title"},[_vm._v(_vm._s(directorie.title))])])}),_vm._l((_vm.filterFiles),function(file,index){return [_c('div',{key:'file-' + index,staticClass:"file-base"},[_c('el-tooltip',[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(
                  '文件名: ' +
                  file.Title +
                  '<br />文件大小: ' +
                  _vm.formatSize(file.Size) +
                  '<br />上传时间: ' +
                  _vm.formatDate(file.LastModified)
                )},slot:"content"}),_c('div',{staticClass:"file-item"},[_c('img',{staticClass:"dir-pic",attrs:{"alt":"File icon","src":_vm.pics.filePic}}),_c('div',{staticClass:"dir-title"},[_vm._v(_vm._s(file.Title))])])]),_c('div',{staticClass:"file-item-button"},[_c('i',{staticClass:"icon icon-delete el-icon-delete-solid",attrs:{"title":"删除文件"},on:{"click":function($event){return _vm.deleteFile(file)}}})])],1)]})]]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }