<template>
  <div class="container">
    <div class="main-title">
      <div>用户空间管理</div>

      <div class="button-bar">
        <el-tooltip content="刷新">
          <el-button @click="refresh" size="mini" circle icon=" el-icon-refresh" />
        </el-tooltip>

        <el-tooltip v-if="directoryPrefix" content="上一级">
          <el-button @click="goBack" size="mini" circle icon="el-icon-back" />
        </el-tooltip>

        <el-tooltip v-if="!isUserList" content="回到用户目录">
          <el-button @click="goTop" size="mini" circle icon="el-icon-user" />
        </el-tooltip>

        <el-input
          placeholder="请输入过滤内容"
          v-model="searchKey"
          clearable
          style="padding-left: 10px"
          size="mini"
        />
      </div>
    </div>

    <div class="dir-container">
      <template v-if="isUserList">
        <template v-if="users.length == 0">
          <div class="empty-data">
            <el-empty :image-size="100" description="暂无数据"></el-empty>
          </div>
        </template>
        <template v-else>
          <div
            :title="
              '使用空间: ' +
              parseFloat(user.size / 1024 / 1024).toFixed(2) +
              ' M\n总空间: ' +
              parseFloat(user.user_space_size / 1024 / 1024).toFixed(2) +
              ' M'
            "
            @click="enterUser(user)"
            class="dir-item"
            v-for="(user, index) in users"
            :key="index"
          >
            <img alt="User Icon" class="dir-pic" :src="pics.userPic" />
            <el-progress :stroke-width="8" :percentage="user.percentage"></el-progress>

            <div class="dir-title">{{ user.title }}</div>
          </div>
        </template>
      </template>

      <template v-else>
        <template v-if="directories.length === 0 && filterFiles.length === 0">
          <div class="empty-data">
            <el-empty :image-size="100" description="暂无数据"></el-empty>
          </div>
        </template>
        <template v-else>
          <div
            @click="enterDirectory(directorie.Prefix)"
            class="dir-item"
            v-for="(directorie, index) in directories"
            :key="'dir-' + index"
          >
            <img alt="Dir icon" class="dir-pic" :src="pics.dirPic" />
            <div class="dir-title">{{ directorie.title }}</div>
          </div>

          <template v-for="(file, index) in filterFiles">
            <div :key="'file-' + index" class="file-base">
              <el-tooltip>
                <div
                  slot="content"
                  v-html="
                    '文件名: ' +
                    file.Title +
                    '<br />文件大小: ' +
                    formatSize(file.Size) +
                    '<br />上传时间: ' +
                    formatDate(file.LastModified)
                  "
                ></div>
                <div class="file-item">
                  <img alt="File icon" class="dir-pic" :src="pics.filePic" />
                  <div class="dir-title">{{ file.Title }}</div>
                </div>
              </el-tooltip>
              <div class="file-item-button">
                <!-- <i title="下载文件" class="icon icon-download el-icon-download"></i> -->
                <i
                  @click="deleteFile(file)"
                  title="删除文件"
                  class="icon icon-delete el-icon-delete-solid"
                ></i>
              </div>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { getUserList, getDirectoryList, deleteFile } from '@/common/userspace';
import dayjs from 'dayjs';

const UserSpace = 'UserSpace/';

export default {
  data() {
    return {
      searchKey: '',
      isUserList: true,
      user: null,
      users: [],
      directoryPrefix: null,
      directories: [],
      files: [],
      pics: {
        userPic: require('@/assets/userdir.png'),
        dirPic: require('@/assets/dir.png'),
        filePic: require('@/assets/file.png'),
      },
    };
  },
  computed: {
    filterFiles() {
      let filters = [];
      for (let file of this.files) {
        if (file['Title'].includes(this.searchKey)) {
          filters.push(file);
        }
      }

      return filters;
    },
  },
  async created() {
    await this.loadUsers();
  },
  methods: {
    formatDate(data) {
      return dayjs(data).format('YYYY-MM-DD HH:mm:ss');
    },
    formatSize(size) {
      // parseFloat(file.Size / 1024 / 1024).toFixed(2);
      if (size < 1024) {
        return size + ' B';
      } else if (size < 1024 * 1024) {
        return parseFloat(size / 1024).toFixed(2) + ' KB';
      } else if (size < 1024 * 1024 * 1024) {
        return parseFloat(size / 1024 / 1024).toFixed(2) + ' MB';
      } else {
        return parseFloat(size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
      }
    },
    async refresh() {
      if (this.isUserList) {
        await this.loadUsers();
      } else {
        if (this.directoryPrefix) {
          await this.loadDirectory(this.directoryPrefix);
        } else {
          this.enterUser(this.user);
        }
      }
    },
    goTop() {
      this.isUserList = true;
      this.user = null;
      this.directoryPrefix = null;
      this.directories = [];
      this.files = [];
    },
    async goBack() {
      console.log(this.directoryPrefix);
      let dirArr = this.directoryPrefix.split('/').slice(0, -2);
      let backPath = dirArr.join('/') + '/';
      // console.log(backPath, dirArr.length);
      if (dirArr.length === 2) {
        this.directoryPrefix = null;
      } else {
        this.directoryPrefix = backPath;
      }
      await this.loadDirectory(backPath);
    },
    async loadUsers() {
      let resp = await getUserList();
      this.users = resp.result;
    },
    async loadDirectory(subpath) {
      this.isUserList = false;
      let resp = await getDirectoryList({ subpath });

      this.directories = resp.result.directories;
      this.files = resp.result.files;
    },
    async enterUser(user) {
      this.user = user;
      await this.loadDirectory(UserSpace + user.id + '/');
    },
    async enterDirectory(directoriePrefix) {
      this.directoryPrefix = directoriePrefix;

      await this.loadDirectory(directoriePrefix);
    },
    async deleteFile(file) {
      console.log(file);
      await deleteFile({
        path: file.Key,
      });
      await this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  user-select: none;

  .main-title {
    font-size: 30px;
    flex-wrap: nowrap;
    display: flex;
    margin: 12px 0;

    .button-bar {
      display: flex;
      align-content: center;
      align-items: center;
      margin: 0 20px;
    }
  }

  .dir-container {
    display: flex;

    flex-wrap: wrap;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 2px 2px 4px 1px #4444441f;

    .empty-data {
      width: 100%;
    }

    .dir-item,
    .file-item {
      width: 140px;
      text-align: center;
      margin: 12px 12px;
      padding: 6px;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 4px;

      &:hover {
        border: 1px solid #bce4fc;
        background-color: #f1f9fd;
      }

      .dir-pic {
        width: 96px;
      }

      .dir-title {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .file-base {
      position: relative;

      .file-item-button {
        display: none;
        position: absolute;
        right: 18px;
        top: 18px;

        .icon {
          cursor: pointer;
          font-size: 20px;
          background-color: #fff;
          border-radius: 4px;
          border: 1px solid #1fb2f7;

          color: #1fb2f7;
          margin: 0 0 0 4px;

          &:hover {
            background-color: #e7f7ff;
          }
        }
      }

      &:hover .file-item-button {
        display: block;
      }
    }
  }
}
</style>
